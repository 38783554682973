exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nieuws-js": () => import("./../../../src/pages/nieuws.js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-voorwie-js": () => import("./../../../src/pages/voorwie.js" /* webpackChunkName: "component---src-pages-voorwie-js" */),
  "component---src-pages-wat-js": () => import("./../../../src/pages/wat.js" /* webpackChunkName: "component---src-pages-wat-js" */),
  "component---src-pages-wieis-js": () => import("./../../../src/pages/wieis.js" /* webpackChunkName: "component---src-pages-wieis-js" */)
}

